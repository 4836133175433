import { AppBar, Button, Container, createMuiTheme, ThemeProvider, Toolbar, Typography } from "@material-ui/core"
import { Link } from "gatsby";
import React, { PropsWithChildren } from "react"
import { Helmet } from "react-helmet"
import "../scss/base.scss"

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#62efff',
            main: '#00bcd4',
            dark: '#008ba3',
            contrastText: '#000000',
        },
        secondary: {
            light: '#aee571',
            main: '#7cb342',
            dark: '#4b830d',
            contrastText: '#000000',
        },
    },
});

export default function MainLayout(props: PropsWithChildren<{}>) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Conspiracy4you</title>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            </Helmet>
            <ThemeProvider theme={theme}>
                <div>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6">
                                Conspiracy4you
                        </Typography>
                            <Typography variant="h6" component={Link} to="/" style={{ marginLeft: "50px", color: "#000000", textDecoration: "none" }}>
                                Home
                        </Typography>
                            <Typography variant="h6" component={Link} to="/quiz" style={{ marginLeft: "50px", color: "#000000", textDecoration: "none" }}>
                                Quiz
                        </Typography>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="lg">
                        {props.children}
                    </Container>
                    <div className="footer">
                        © Nathan Kutzan, Ziad El-Rady
                    </div>
                </div>
            </ThemeProvider>
        </>
    )
}